@import './src/scss/variables';

.dark-theme {
	.grid__row .grid__cell.number:not(.valid)::after {
		border-color: $dark-color2;
		background-color: $dark-color;
	}

	.game__content-overlay {
		background: $dark-color2;
	}
}