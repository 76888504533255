@import './src/scss/variables';
@import './src/scss/game';

$grid_cell_size: calc(131px - (var(--grid-size) * 8px));
$max_grid_size: 8;

:root {
  --grid-size: 8;
}

.grid {
  position: relative;

  &__row {
    display: flex;
    margin: 0;
    text-align: center;

    .grid__cell {
      position: relative;

      &.number:not(.valid) {
        cursor: pointer;

        &::after {
          content: "";
          top: 0;
          left: 0;
          position: absolute;
          border: .5rem solid $color2;
          border-radius: 50%;
          box-sizing: border-box;
          width: 100%;
          aspect-ratio: 1 / 1;
          background-color: $color;
        }
      }

      &.number.visible {
        cursor: pointer;
        background-color: unset;
        font-size: $font-size4;

        &::after {
          content: unset;
        }
      }

      &.valid:not(.visible) {
        * {
          visibility: hidden;
        }
      }
    }
  }

  &__cell {
    width: $grid_cell_size;
    height: $grid_cell_size;
    line-height: $grid_cell_size;
    box-sizing: content-box !important;

    &:last-child {
      margin-right: 0;
    }
  }
}

.game__content {
	&-overlay {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: $color2;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	}

	&-list {
		display: flex;
    flex-wrap: wrap;
		gap: 1rem;

		li {
			display: flex;
			align-items: center;
			gap: .5rem;

			a {
				display: flex;
				align-items: center;
				gap: .5rem;
				cursor: pointer;
			}
		}
	}
}

@import 'dark-theme';